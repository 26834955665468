*, *:before, *::after {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    list-style: none;
    text-decoration: none;
    box-sizing: border-box;    
}

:root {
    --primary-hue: 210;
    --gray-hue: 240;
    --color-primary: hsl(var(--primary-hue), 100%, 50%);
    --color-secondary: #ffb116;
    --color-gray-100: hsl(var(--gray-hue), 47%, 94%);
    --color-gray-200: hsl(var(--gray-hue), 16%, 41%);
    --color-gray-300: hsl(var(--gray-hue), 44%, 25%);
    --color-gray-400: hsl(var(--gray-hue), 48%, 15%);

    --container-width-lg: 95%;
    --container-width-md: 90%;

    --transition: all 500ms ease;
}

/* GENERAL STYLES */
body {
    font-family: 'Montserrat', sans-serif;
    color: white;
    line-height: 1.7;
    overflow-x: hidden;
    background-color: black;
    /* background-color: rgba(20, 244, 244, 0.972); */
}

.container {
    max-width: 1920px;
    background-color: black;
}

p, h1, h2, h3, h4, h5 {
    color: white;
    font-size: 14px;
    padding: 8px 8px 25px;
    font-weight: 300;
}

h1 {
    font-size: 26px;
    font-weight: 300;
}

h2 {
    font-size: 24px;
    font-weight: 450;
}

h3 {
    font-size: 15px;
}

h4 {
    font-weight: 400;
    font-size: 16px;
}

p {
    font-weight: 350;
    font-size: 15px;
}

a {
    color: white;
    font-size: 10.5px;
    padding: 8px 8px 25px;
}

img {
    display: block;
    object-fit: cover;
    width: 100%;
}

.btn {
    color: var(--color-gray-100);
    width: fit-content;
    margin: 0 auto;
    padding: 0.9rem 2rem;
    background: var(--color-primary);
    border-radius: 1.5rem;
    transition: var(--transition);
}

.btn:hover {
    background: var(--color-secondary);
    color: var(--color-gray-400);
}

.btn.lg {
    padding: 1.2rem 3rem;
    border-radius: 2rem;
    font-size: 1.1rem;
}

.btn.sm {
    padding: 0.4rem 1.2rem;
    font-size: 0.9rem;
}

.btn.sm:hover {
    background: var(--color-gray-100);
}



/* MEDIA QUERIES (MEDIUM SCREENS) */
@media screen and (max-width: 1024px) {

}

/* MEDIA QUERIES (SMALL SCREENS) */
@media screen and (max-width: 600px) {

}