/* HEADER */
.main_header {
    max-width: 100vw;
    height: calc(100vh);
    display: grid;
    /* place-items: center; */
    margin-top: 3rem;
}

.main_header-container {
  height: 100%;
  display: grid;
  align-items: center;
  justify-items: center;
}

.main_header_center {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
}
  
.main_header_center h1 {
  font-size: 14pt;
  font-weight: 500;
  /* letter-spacing: 20pt; */
  /* line-height: 32pt; */
  color: white;
  text-transform: uppercase;
  text-align: center;
  /* letter-spacing: 1.5rem; */
}
  
.main_header_center h2 {
  font-size: 14pt;
  font-weight: 180;
  letter-spacing: 4pt;
  color: white;
  font-family: 'Cardo', serif;
  text-align: center;
}

.header_line {
    margin: 20px auto;
    width: 25px;
    height: 1px;
    background-color: #9e9e9e;
}

/* HEADER SOCIAL */
.main_header_social {
    position: fixed;
    left: 48px;
    bottom: 54px;
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 14pt;
    line-height: 1.75;
    text-align: center;
    z-index: 5;
}
  
  .main_header_social::before {
    display: block;
    content: "";
    width: 1.5px;
    height: 36px;
    background-color: #757575;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 12px;
  }
  
  .main_header_social li {
    padding-left: 0;
  }

  .main_header_social li a {
    font-size: 22px;
  }
  
  .main_header_social li a,
  .main_header_social li a:visited {
    color: #757575;
  }
  
  .main_header_social li a:hover,
  .main_header_social li a:focus,
  .main_header_social li a:active {
    color: white;
  }

/* EMAIL CONTAINER */
.email_container {
    position: fixed;
    right: 48px;
    bottom: 30px;
    margin: 0;
    padding: 0;
    font-size: 14pt;
    line-height: 1.75;
    text-align: center;
    z-index: 5;
    writing-mode: vertical-lr;
}
  
  .email_container::before {
    position: fixed;
    display: block;
    content: "";
    width: 1.5px;
    height: 36px;
    background-color: #757575;
    right: 69px;
    /* margin-left: auto; */
    /* margin-right: auto; */
    /* margin-bottom: 12px; */
    z-index: 5;
    /* right: 48px; */
    bottom: 280px;
  }


  /* MEDIA QUERIES (MEDIUM SCREENS) */
@media screen and (max-width: 1024px) {
  .email_container {
    display: none;
  }
  .main_header_social { 
    display: none;
  }
}