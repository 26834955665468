
/* ABOUT */
.about_container {
    display: grid;
    justify-content: center;
    align-items: center;
}

.about_wrapper {
    display: grid;
    justify-items: left;
    width: 60vw;
}

.about {
    display: flex;
    background-color: black;
    justify-content: center;
    padding-top: 5rem;
    /* padding-bottom: 3rem; */
}
